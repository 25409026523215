
import { defineComponent, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";
import { hideModal } from "@/presentation/helper/Dom";
import SwalNotification from "@/presentation/plugins/SwalNotification";
import {
  COMPONENT_NAME,
  CUSTOM_ERROR_MESSAGES,
  PAGE_TITLE_PATH,
  SWAL_MESSAGES,
} from "@/domain/Constant";
import { useI18n } from "vue-i18n";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import { ErrorMessage, Field, Form } from "vee-validate";
import { setCurrentPageTitle } from "@/presentation/helper/Breadcrumb";
import AddBreed from "@/presentation/components/AddBreed.vue";
import { BreedListModel } from "@/domain/breed/breedList/model/BreedListModel";
import { BreedRemoveModel } from "@/domain/breed/breedRemove/model/BreedRemoveModel";
import { BreedUpdateModel } from "@/domain/breed/breedUpdate/model/BreedUpdateModel";
import { AnimalTypeListModel } from "@/domain/animal/animalTypeList/model/AnimalTypeListModel";
import { PriceAddModel } from "@/domain/breed/priceAdd/model/PriceAddModel";

export default defineComponent({
  name: COMPONENT_NAME.ANIMAL_LIST,
  components: {
    Field, Form, ErrorMessage,
    AddBreed, 
  },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const newTargetModalRef = ref<null | HTMLElement>(null);
    const swalNotification = new SwalNotification();
    const loading = ref<boolean>(false);
    const breedList = ref<BreedListModel[]>([]);
    const originalBreedList = ref<BreedListModel[]>([]);
    const breedId = ref<number>(0);

    const animalBreed = ref<BreedUpdateModel>({});
    const isLoading = ref<boolean>(false);
    const animalTypeList = ref<AnimalTypeListModel[]>([]);

    const breedPrice = ref<PriceAddModel>({
      unitPrice: 0,
      animalBreed: 0,
    });

    watch(
      () => store.getters.newBreed,
      () => {
        if (store.getters.newBreed) getBreeds(1);
      }
    );

    //Create form validation object
    const form = Yup.object().shape({
      unitPrice: Yup.string()
        .required(t(CUSTOM_ERROR_MESSAGES.required))
        .label("unitPrice"),
    });

        //Create form validation object
        const updateBreedForm = Yup.object().shape({
      name: Yup.string()
        .required(t(CUSTOM_ERROR_MESSAGES.required))
        .label("Name"),
      description: Yup.string()
        .required(t(CUSTOM_ERROR_MESSAGES.required))
        .label("Description")
    });


    const currentPage = ref(1);
    const pageSize = ref(10);
    const totalItems = ref(0);
    const pageCount = ref(1);

    const breedController = store.state.ControllersModule.breedController;
    const animalController = store.state.ControllersModule.animalController;

    const getAnimalTypes = () => {
      const animalTypeListModel: AnimalTypeListModel = {
        animalType: {
          id: 0,
        },
        page: 1,
        pageSize: 10,
      };

      animalController
        .animalTypeList(animalTypeListModel)
        .then((response) => {
          if (response.isSuccess) {
            response.getValue().forEach((animalType) => {
              animalTypeList.value.push(animalType);
            });
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    const getBreeds = (page) => {
      isLoading.value = true;
      breedList.value = [];
      originalBreedList.value = [];

      if (page < 1) {
        page = 1;
      }
      if (page > pageCount.value) {
        page = pageCount.value;
      }
      currentPage.value = page;

      const breedListModel: BreedListModel = {
        breed: {
          id: 0,
        },
        page: page,
        pageSize: pageSize.value,
        tenantEstateAnimalUnitPrice: true,
      };

      breedController
        .breedList(breedListModel)
        .then((response) => {
          if (response.isSuccess) {
            response.getValue().forEach((breed) => {
              totalItems.value = breed.pagination.total;
              currentPage.value = breed.pagination.page;
              pageCount.value = breed.pagination.pageCount;
              breedList.value.push(breed);
              originalBreedList.value.push(breed);
            });
          } else {
            breedList.value = [];
            originalBreedList.value = [];

            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
          isLoading.value = false;
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    const removeBreed = async (breedId: number | undefined) => {
      const breedRemove: BreedRemoveModel = {
        id: breedId,
      };
      swalNotification
        .withButtons(
          "Emin misiniz?",
          "Silmek istediğinize emin misiniz? Bu işlem geri alınamaz.",
          "Evet",
          "Hayır"
        )
        .then((result) => {
          if (result.isConfirmed) {
            breedController
              .removeBreed(breedRemove)
              .then((response) => {
                if (response.isSuccess) {
                  swalNotification.success(
                    t(SWAL_MESSAGES.SUCCESS_REMOVE_BREED),
                    t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
                  );
                  window.location.reload();
                } else {
                  swalNotification.error(
                    response.error.cause.cause +
                      t(SWAL_MESSAGES.ERROR_CODE) +
                      response.error.code +
                      response.error.cause.code,
                    t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
                  );
                }
              })
              .catch((e) => {
                swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
              });
          }
        });
    };

    const selectBreed = (id, price) => {
      breedId.value = id;
      store.dispatch("selectedBreedId", breedId.value);
      breedPrice.value.unitPrice = price;
    };

    const updateBreed = async (breed: BreedUpdateModel) => {
      animalBreed.value = { ...breed };
    };

    const updateBreedSubmit = () => {
      loading.value = true;

      breedController
        .updateBreed(animalBreed.value)
        .then((response) => {
          loading.value = false;
          if (response.getValue()) {
            Swal.fire({
              text: t(SWAL_MESSAGES.SUCCESS_UPDATE_BREED),
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT),
              customClass: {
                confirmButton: "btn btn-primary",
              },
            }).then(() => {
              hideModal(newTargetModalRef.value);
              window.location.reload();
            });
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    const updatePriceSubmit = async () => {
      const priceAddModel: PriceAddModel = {
        animalBreed: store.getters.selectedBreedId,
        unitPrice: breedPrice.value.unitPrice,
      };

      breedController.updatePrice(priceAddModel).then((response) => {
        if (response.isSuccess) {
          swalNotification.success(
            t(SWAL_MESSAGES.SUCCESS_UPDATE_PRICE),
            t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
          );
          window.location.reload();
        } else {
          swalNotification.error(
            response.error.cause.cause +
              t(SWAL_MESSAGES.ERROR_CODE) +
              response.error.code +
              response.error.cause.code,
            t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
          );
        }
      });
    };

    onMounted(async () => {
      setCurrentPageTitle(t(PAGE_TITLE_PATH.BREED));
      getAnimalTypes();
      getBreeds(1);
    });

    return {
      PAGE_TITLE_PATH,
      removeBreed,
      updateBreed,
      selectBreed,
      isLoading,
      loading,
      animalBreed,
      updatePriceSubmit,
      updateBreedSubmit,
      getBreeds,
      originalBreedList,
      breedList,
      animalTypeList,
      totalItems,
      currentPage,
      pageCount,
      pageSize,
      breedPrice,
      currencyValue: "TL",
      form,
      updateBreedForm,
    };
  },
});
