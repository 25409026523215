
import { defineComponent, onMounted, ref } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { BreedAddModel } from "@/domain/breed/breedAdd/model/BreedAddModel";
import { useStore } from "vuex";
import * as Yup from "yup";
import { CUSTOM_ERROR_MESSAGES, SWAL_MESSAGES } from "@/domain/Constant";
import { useI18n } from "vue-i18n";
import { ErrorMessage, Field, Form } from "vee-validate";
import SwalNotification from "../plugins/SwalNotification";
import { AnimalTypeListModel } from "@/domain/animal/animalTypeList/model/AnimalTypeListModel";
import { hideModal } from "../helper/Dom";

export default defineComponent({
  name: "new-target-modal",
  components: { Field, Form, ErrorMessage },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const formRef = ref<null | HTMLFormElement>(null);
    const newTargetModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);
    const swalNotification = new SwalNotification();
    const animalTypeList = ref<AnimalTypeListModel[]>([]);

    const breedAddModel = ref<BreedAddModel>({});

    const breedController = store.state.ControllersModule.breedController;
    const animalController = store.state.ControllersModule.animalController;

    if (!breedController) {
      throw new Error("BreedController is not available in Vuex store.");
    }

    //Create form validation object
    const form = Yup.object().shape({
      name: Yup.string()
        .required(t(CUSTOM_ERROR_MESSAGES.required))
        .label("Name"),
      description: Yup.string()
        .required(t(CUSTOM_ERROR_MESSAGES.required))
        .label("Description"),
        type: Yup.string()
        .required(t(CUSTOM_ERROR_MESSAGES.required))
        .label("Type"),
    });

    const getAnimalTypes = () => {
      const animalTypeListModel: AnimalTypeListModel = {
        animalType: {
          id: 0,
        },
        page: 1,
        pageSize: 10,
      };

      animalController
        .animalTypeList(animalTypeListModel)
        .then((response) => {
          if (response.isSuccess) {
            response.getValue().forEach((animalType) => {
              animalTypeList.value.push(animalType);
            });
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    const submit = () => {
      loading.value = true;

      breedController
        .addBreed(breedAddModel.value)
        .then((response) => {
          loading.value = false;
          if (response.getValue()) {
            store.dispatch("newBreed", store.getters.newBreed + 1 );
            Swal.fire({
              text: t(SWAL_MESSAGES.SUCCESS_ADD_BREED),
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT),
              customClass: {
                confirmButton: "btn btn-primary",
              },
            }).then(() => {
              hideModal(newTargetModalRef.value);
            });
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    onMounted(async () => {
      getAnimalTypes();
    });

    return {
      breedAddModel,
      submit,
      animalTypeList,
      loading,
      formRef,
      newTargetModalRef,
      form,
    };
  },
});
