<template>
  <div class="card">
    <div class="card-header border-0 pt-3">
      <h3 class="card-title align-items-start flex-column">
        <div class="card-title card-label fw-bolder text-dark">
          {{ $t(PAGE_TITLE_PATH.BREED) }}
        </div>
      </h3>
      <div class="card-toolbar" v-if="originalBreedList.length > 0">
        <button
          type="button"
          class="btn btn-sm btn-primary"
          data-bs-toggle="modal"
          data-bs-target="#add_breed_modal"
        >
          <span class="svg-icon svg-icon-2">
            <inline-svg src="/media/icons/duotune/arrows/arr078.svg" />
          </span>
          {{ $t("menuPages.breed.button.addBreed") }}
        </button>
      </div>
    </div>
    <div class="card-body pt-0">
      <div class="dataTables_wrapper dt-bootstrap4 no-footer">
        <div
          v-if="isLoading"
          style="
            height: 500px;
            display: flex;
            justify-content: center;
            align-items: center;
          "
        >
          <div class="text-center px-5 pb-7">
            <img
              alt="Logo"
              class="max-h-35px"
              src="/media/loading_icon.gif"
              style="width: 40px"
            />
          </div>
        </div>
        <div class="table-responsive" v-else-if="breedList.length != 0">
          <table
            class="
              table
              align-middle
              table-row-dashed
              fs-6
              gy-5
              dataTable
              no-footer
            "
            id="kt_customers_table"
            role="grid"
          >
            <!--begin::Table-->
            <thead>
              <tr
                class="
                  text-start text-gray-400
                  fw-bolder
                  fs-7
                  text-uppercase
                  gs-0
                "
                role="row"
              >
                <td>#</td>
                <td>{{ $t("menuPages.breed.name") }}</td>
                <td>{{ $t("menuPages.breed.description") }}</td>
                <td>{{ $t("menuPages.breed.animalType") }}</td>
                <td>{{ $t("menuPages.breed.isDefault") }}</td>
                <td>{{ $t("menuPages.breed.price") }}</td>
                <td>{{ $t("common.table.process") }}</td>
              </tr>
            </thead>

            <tbody class="fw-bold text-gray-600">
              <tr class="odd" v-for="breed in breedList" :key="breed.breed.id">
                <td>{{ breed.breed.id }}</td>
                <td>
                  <a>{{ breed.breed.name }}</a>
                </td>
                <td>
                  <a>{{ breed.breed.description }}</a>
                </td>
                <td>
                  <a>{{ breed.breed.animalType }}</a>
                </td>
                <td>
                  <span
                    v-if="!breed.breed.isDefault"
                    class="badge fs-8 fw-bolder badge-light-warning"
                  >
                    {{ $t("common.button.no") }}
                  </span>
                  <span v-else class="badge fs-8 fw-bolder badge-light-success">
                    {{ $t("common.button.yes") }}
                  </span>
                </td>
                <td>
                  <a v-if="breed.breed.price">{{ breed.breed.price }} TL</a>
                  <a v-else> Yok </a>
                </td>
                <td>
                  <a
                    href="#"
                    data-bs-toggle="modal"
                    data-bs-target="#add_price_modal"
                    @click="selectBreed(breed.breed.id, breed.breed.price)"
                    class="
                      btn btn-icon btn-primary btn-active-color-light btn-sm
                      me-1
                    "
                  >
                    <span class="svg-icon svg-icon-3">
                      <inline-svg
                        src="/media/icons/duotune/finance/fin010.svg"
                      />
                    </span>
                  </a>
                  <a
                    v-if="!breed.breed.isDefault"
                    @click="updateBreed(breed.breed)"
                    class="
                      btn btn-icon btn-warning btn-active-color-primary btn-sm
                      me-1
                    "
                    data-bs-toggle="modal"
                    data-bs-target="#update_breed_modal"
                  >
                    <span class="svg-icon svg-icon-3">
                      <inline-svg src="/media/icons/duotune/art/art005.svg" />
                    </span>
                  </a>
                  <a
                    v-if="!breed.breed.isDefault"
                    @click="removeBreed(breed.breed.id)"
                    class="
                      btn btn-icon btn-danger btn-active-color-light btn-sm
                    "
                  >
                    <span class="svg-icon svg-icon-3">
                      <inline-svg
                        src="/media/icons/duotune/general/gen040.svg"
                      />
                    </span>
                  </a>
                </td>
              </tr>
            </tbody>
            <!--end::Table-->
          </table>
        </div>
        <div v-else class="card-px text-center">
          <div class="text-center px-5 pb-7">
            <img
              src="/media/animal/animal.jpg"
              class="symbol symbol-100px symbol-lg-160px mw-100 mh-150px"
            />
          </div>

          <!--begin::Description-->
          <p class="text-gray-400 fs-5 fw-bold">
            <span>{{ $t("menuPages.breed.zeroCount") }}</span>
          </p>
          <!--end::Description-->
          <!--begin::Button-->
          <a
            href="#"
            class="btn btn-sm btn-primary"
            id="kt_toolbar_primary_button"
            data-bs-toggle="modal"
            data-bs-target="#add_breed_modal"
          >
            {{ $t("menuPages.breed.button.addBreed") }}
          </a>
          <!--end::Button-->
        </div>
      </div>

      <!--begin::Pagination-->
      <div
        class="d-flex flex-stack flex-wrap pt-10"
        v-if="originalBreedList.length != 0"
      >
        <!--begin::Controls-->
        <div class="d-flex flex-wrap my-1">
          <!--begin::Select wrapper-->
          <div class="m-0">
            <!--begin::Select-->
            <select
              name="status"
              data-control="select2"
              data-hide-search="true"
              class="
                form-select form-select-white form-select-sm
                fw-bolder
                w-125px
              "
              v-model="pageSize"
              @change="getBreeds(1)"
            >
              <option value="5">5</option>
              <option value="10" selected>10</option>
              <option value="25">25</option>
              <option value="50">50</option>
            </select>
            <span>{{ $t("common.table.pagination.desc2") }}</span>

            <!--end::Select-->
          </div>
          <!--end::Select wrapper-->
        </div>
        <!--end::Controls-->

        <div class="fs-6 fw-bold text-gray-700">
          {{ totalItems }} {{ $t("common.table.pagination.desc") }}
          {{ (currentPage - 1) * pageSize + 1 }} -
          {{ Math.min(currentPage * pageSize, totalItems) }}
          {{ $t("common.table.pagination.desc1") }}
        </div>

        <!--begin::Pages-->
        <ul class="pagination">
          <li
            class="page-item previous"
            @click="getBreeds(currentPage - 1)"
            :class="{ disabled: currentPage === 1 }"
          >
            <a href="#" class="page-link"><i class="previous"></i></a>
          </li>

          <li
            v-for="page in pageCount"
            :key="page"
            class="page-item"
            :class="{ active: currentPage === page }"
          >
            <a href="#" class="page-link" @click="getBreeds(page)">{{
              page
            }}</a>
          </li>

          <li
            class="page-item next"
            @click="getBreeds(currentPage + 1)"
            :class="{ disabled: currentPage === pageCount }"
          >
            <a href="#" class="page-link"><i class="next"></i></a>
          </li>
        </ul>
        <!--end::Pages-->
      </div>
      <!--end::Pagination-->
    </div>
  </div>
  <AddBreed></AddBreed>

  <div
    class="modal fade"
    id="update_breed_modal"
    ref="newTargetModalRef"
    tabindex="-1"
    aria-hidden="true"
  >
    <!--begin::Modal dialog-->
    <div class="modal-dialog modal-dialog-centered mw-800px">
      <!--begin::Modal content-->
      <div class="modal-content rounded">
        <!--begin::Modal header-->
        <div class="modal-header pb-0 border-0 justify-content-end">
          <!--begin::Close-->
          <div
            class="btn btn-sm btn-icon btn-active-color-primary"
            data-bs-dismiss="modal"
          >
            <span class="svg-icon svg-icon-1">
              <inline-svg src="/media/icons/duotune/arrows/arr061.svg" />
            </span>
          </div>
          <!--end::Close-->
        </div>
        <!--begin::Modal header-->

        <!--begin::Modal body-->
        <div class="modal-body scroll-y px-10 px-lg-15 pt-0 pb-15">
          <!--begin:Form-->
          <Form
            id="vaccinate_modal_form"
            @submit="updateBreedSubmit"
            :validation-schema="updateBreedForm"
          >
            <!--begin::Heading-->
            <div class="mb-13 text-center">
              <!--begin::Title-->
              <h1 class="mb-3">{{ $t("menuPages.addBreed.updateBreed") }}</h1>
              <!--end::Title-->

              <!--begin::Description-->
              <div class="text-gray-400 fw-bold fs-5">
                {{ $t("menuPages.addBreed.pageDescription") }}
              </div>
              <!--end::Description-->
            </div>
            <!--end::Heading-->

            <!--begin::Input group-->
            <div class="row g-9 mb-8">
              <!--begin::Col-->
              <div class="col-md-6 fv-row">
                <label class="required fs-6 fw-bold mb-2">{{
                  $t("menuPages.addBreed.breedName")
                }}</label>
                <Field
                  class="form-control form-control-solid"
                  v-model="animalBreed.name"
                  :placeholder="$t('menuPages.addBreed.enterBreedName')"
                  name="name"
                  type="text"
                />
                <div class="fv-plugins-message-container">
                  <div class="fv-help-block">
                    <ErrorMessage name="name" />
                  </div>
                </div>
              </div>
              <!--end::Col-->

              <!--begin::Col-->
              <div class="col-md-6 fv-row">
                <label class="required fs-6 fw-bold mb-2">{{
                  $t("menuPages.addBreed.animalType")
                }}</label>

                <!--begin::Input-->
                <div class="position-relative align-items-center">
                    <el-select
                      v-model="animalBreed.animalType"
                      :placeholder="$t('menuPages.addBreed.selectAnimalType')"
                      style="background-color:#f5f8fa; border-color: #f5f8fa"
                      name="animalType"
                      as="select"
                    >
                      <el-option
                      style="background-color:#f5f8fa"
                        :label="animalType.animalType.name"
                        :value="animalType.animalType.id"
                        v-for="animalType in animalTypeList"
                        :key="animalType.animalType.id"
                        >{{ animalType.animalType.name }}</el-option
                      >
                    </el-select>
                  <!--
                  <div class="position-relative align-items-center">
                    <Field
                      name="animalType"
                      data-control="select1"
                      data-hide-search="true"
                      data-placeholder='{{ $t("menuPages.addBreed.selectAnimalType") }}'
                      class="form-select form-select-solid"
                      as="select"
                      v-model="animalBreed.animalType"
                    >
                    <option value="0">
                      {{ $t("menuPages.addBreed.selectAnimalType") }}
                    </option>
                      <option
                        :label="animalType.animalType.name"
                        :value="animalType.animalType.id"
                        v-for="animalType in animalTypeList"
                        :key="animalType.animalType.id"
                      >
                        {{ animalType.animalType.name }}
                      </option>
                    </Field>
                    <div class="fv-plugins-message-container">
                      <div class="fv-help-block">
                        <ErrorMessage name="animalType" />
                      </div>
                    </div>
                  </div>
                  -->
                </div>
                <!--end::Input-->
              </div>
              <!--end::Col-->
            </div>
            <!--end::Input group-->

            <!--begin::Input group-->
            <div class="d-flex flex-column mb-8 fv-row">
              <!--begin::Label-->
              <label class="d-flex align-items-center fs-6 fw-bold mb-2">
                <span class="required">{{
                  $t("menuPages.addBreed.description")
                }}</span>
              </label>
              <!--end::Label-->
              <Field
                class="form-control form-control-solid"
                v-model="animalBreed.description"
                :placeholder="$t('menuPages.addBreed.enterDescription')"
                name="description"
                type="text"
              />
              <div class="fv-plugins-message-container">
                <div class="fv-help-block">
                  <ErrorMessage name="description" />
                </div>
              </div>
            </div>
            <!--end::Input group-->

            <!--begin::Actions-->
            <div class="text-center">
              <!--begin::Button-->
              <button
                :data-kt-indicator="loading ? 'on' : null"
                class="btn btn-lg btn-primary"
                type="submit"
              >
                <span v-if="!loading" class="indicator-label">
                  {{ $t("common.button.update") }}
                  <span class="svg-icon svg-icon-3 ms-2 me-0">
                    <inline-svg src="icons/duotune/arrows/arr064.svg" />
                  </span>
                </span>
                <span v-if="loading" class="indicator-progress">
                  {{ $t("common.button.loader") }}
                  <span
                    class="spinner-border spinner-border-sm align-middle ms-2"
                  ></span>
                </span>
              </button>
              <!--end::Button-->
            </div>
            <!--end::Actions-->
          </Form>
          <!--end:Form-->
        </div>
        <!--end::Modal body-->
      </div>
      <!--end::Modal content-->
    </div>
    <!--end::Modal dialog-->
  </div>



























































































  <div
    class="modal fade"
    id="add_price_modal"
    ref="newTargetModalRef"
    tabindex="-1"
    aria-hidden="true"
  >
    <!--begin::Modal dialog-->
    <div class="modal-dialog modal-dialog-centered mw-800px">
      <!--begin::Modal content-->
      <div class="modal-content rounded">
        <!--begin::Modal header-->
        <div class="modal-header pb-0 border-0 justify-content-end">
          <!--begin::Close-->
          <div
            class="btn btn-sm btn-icon btn-active-color-primary"
            data-bs-dismiss="modal"
          >
            <span class="svg-icon svg-icon-1">
              <inline-svg src="/media/icons/duotune/arrows/arr061.svg" />
            </span>
          </div>
          <!--end::Close-->
        </div>
        <!--begin::Modal header-->

        <!--begin::Modal body-->
        <div class="modal-body px-10 px-lg-15 pt-0 pb-15">
          <!--begin:Form-->
          <Form
            id="addPrice_modal_form"
            novalidate="novalidate"
            @submit="updatePriceSubmit"
            :validation-schema="form"
          >
            <!--begin::Heading-->
            <div class="mb-13 text-center">
              <!--begin::Title-->
              <h1 class="mb-3">{{ $t("menuPages.addPrice.pageName") }}</h1>
              <!--end::Title-->

              <!--begin::Description-->
              <div class="text-gray-400 fw-bold fs-5">
                {{ $t("menuPages.addPrice.pageDescription") }}
              </div>
              <!--end::Description-->
            </div>
            <!--end::Heading-->

            <!--begin::Input group-->
            <div class="row g-9 mb-8">
              <!--begin::Col-->
              <div class="col-md-6 fv-row">
                <label class="required fs-6 fw-bold mb-2">{{
                  $t("menuPages.addPrice.price")
                }}</label>

                <Field
                class="form-control form-control-solid"
                  type="number"
                  min="1"
                  v-model="breedPrice.unitPrice"
                  :placeholder="$t('menuPages.addPrice.enterPrice')"
                  name="unitPrice"
                />
                <div class="fv-plugins-message-container">
                  <div class="fv-help-block">
                    <ErrorMessage name="unitPrice" />
                  </div>
                </div>
              </div>
              <!--end::Col-->

              <!--begin::Col-->
              <div class="col-md-6 fv-row">
                <label class="required fs-6 fw-bold mb-2">{{
                  $t("menuPages.addPrice.currency")
                }}</label>
                  <Field 
                  type="text"
                  class="form-control form-control-solid"
                  v-model="currencyValue" 
                  name="currencyValue"
readonly/>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Input group-->

            <!--begin::Actions-->
            <div class="text-center">
              <!--begin::Button-->
              <button
                :data-kt-indicator="loading ? 'on' : null"
                class="btn btn-lg btn-primary"
                type="submit"
              >
                <span v-if="!loading" class="indicator-label">
                  {{ $t("common.button.update") }}
                  <span class="svg-icon svg-icon-3 ms-2 me-0">
                    <inline-svg src="icons/duotune/arrows/arr064.svg" />
                  </span>
                </span>
                <span v-if="loading" class="indicator-progress">
                  {{ $t("common.button.loader") }}
                  <span
                    class="spinner-border spinner-border-sm align-middle ms-2"
                  ></span>
                </span>
              </button>
              <!--end::Button-->
            </div>
            <!--end::Actions-->
          </Form>
          <!--end:Form-->
        </div>
        <!--end::Modal body-->
      </div>
      <!--end::Modal content-->
    </div>
    <!--end::Modal dialog-->
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";
import { hideModal } from "@/presentation/helper/Dom";
import SwalNotification from "@/presentation/plugins/SwalNotification";
import {
  COMPONENT_NAME,
  CUSTOM_ERROR_MESSAGES,
  PAGE_TITLE_PATH,
  SWAL_MESSAGES,
} from "@/domain/Constant";
import { useI18n } from "vue-i18n";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import { ErrorMessage, Field, Form } from "vee-validate";
import { setCurrentPageTitle } from "@/presentation/helper/Breadcrumb";
import AddBreed from "@/presentation/components/AddBreed.vue";
import { BreedListModel } from "@/domain/breed/breedList/model/BreedListModel";
import { BreedRemoveModel } from "@/domain/breed/breedRemove/model/BreedRemoveModel";
import { BreedUpdateModel } from "@/domain/breed/breedUpdate/model/BreedUpdateModel";
import { AnimalTypeListModel } from "@/domain/animal/animalTypeList/model/AnimalTypeListModel";
import { PriceAddModel } from "@/domain/breed/priceAdd/model/PriceAddModel";

export default defineComponent({
  name: COMPONENT_NAME.ANIMAL_LIST,
  components: {
    Field, Form, ErrorMessage,
    AddBreed, 
  },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const newTargetModalRef = ref<null | HTMLElement>(null);
    const swalNotification = new SwalNotification();
    const loading = ref<boolean>(false);
    const breedList = ref<BreedListModel[]>([]);
    const originalBreedList = ref<BreedListModel[]>([]);
    const breedId = ref<number>(0);

    const animalBreed = ref<BreedUpdateModel>({});
    const isLoading = ref<boolean>(false);
    const animalTypeList = ref<AnimalTypeListModel[]>([]);

    const breedPrice = ref<PriceAddModel>({
      unitPrice: 0,
      animalBreed: 0,
    });

    watch(
      () => store.getters.newBreed,
      () => {
        if (store.getters.newBreed) getBreeds(1);
      }
    );

    //Create form validation object
    const form = Yup.object().shape({
      unitPrice: Yup.string()
        .required(t(CUSTOM_ERROR_MESSAGES.required))
        .label("unitPrice"),
    });

        //Create form validation object
        const updateBreedForm = Yup.object().shape({
      name: Yup.string()
        .required(t(CUSTOM_ERROR_MESSAGES.required))
        .label("Name"),
      description: Yup.string()
        .required(t(CUSTOM_ERROR_MESSAGES.required))
        .label("Description")
    });


    const currentPage = ref(1);
    const pageSize = ref(10);
    const totalItems = ref(0);
    const pageCount = ref(1);

    const breedController = store.state.ControllersModule.breedController;
    const animalController = store.state.ControllersModule.animalController;

    const getAnimalTypes = () => {
      const animalTypeListModel: AnimalTypeListModel = {
        animalType: {
          id: 0,
        },
        page: 1,
        pageSize: 10,
      };

      animalController
        .animalTypeList(animalTypeListModel)
        .then((response) => {
          if (response.isSuccess) {
            response.getValue().forEach((animalType) => {
              animalTypeList.value.push(animalType);
            });
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    const getBreeds = (page) => {
      isLoading.value = true;
      breedList.value = [];
      originalBreedList.value = [];

      if (page < 1) {
        page = 1;
      }
      if (page > pageCount.value) {
        page = pageCount.value;
      }
      currentPage.value = page;

      const breedListModel: BreedListModel = {
        breed: {
          id: 0,
        },
        page: page,
        pageSize: pageSize.value,
        tenantEstateAnimalUnitPrice: true,
      };

      breedController
        .breedList(breedListModel)
        .then((response) => {
          if (response.isSuccess) {
            response.getValue().forEach((breed) => {
              totalItems.value = breed.pagination.total;
              currentPage.value = breed.pagination.page;
              pageCount.value = breed.pagination.pageCount;
              breedList.value.push(breed);
              originalBreedList.value.push(breed);
            });
          } else {
            breedList.value = [];
            originalBreedList.value = [];

            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
          isLoading.value = false;
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    const removeBreed = async (breedId: number | undefined) => {
      const breedRemove: BreedRemoveModel = {
        id: breedId,
      };
      swalNotification
        .withButtons(
          "Emin misiniz?",
          "Silmek istediğinize emin misiniz? Bu işlem geri alınamaz.",
          "Evet",
          "Hayır"
        )
        .then((result) => {
          if (result.isConfirmed) {
            breedController
              .removeBreed(breedRemove)
              .then((response) => {
                if (response.isSuccess) {
                  swalNotification.success(
                    t(SWAL_MESSAGES.SUCCESS_REMOVE_BREED),
                    t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
                  );
                  window.location.reload();
                } else {
                  swalNotification.error(
                    response.error.cause.cause +
                      t(SWAL_MESSAGES.ERROR_CODE) +
                      response.error.code +
                      response.error.cause.code,
                    t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
                  );
                }
              })
              .catch((e) => {
                swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
              });
          }
        });
    };

    const selectBreed = (id, price) => {
      breedId.value = id;
      store.dispatch("selectedBreedId", breedId.value);
      breedPrice.value.unitPrice = price;
    };

    const updateBreed = async (breed: BreedUpdateModel) => {
      animalBreed.value = { ...breed };
    };

    const updateBreedSubmit = () => {
      loading.value = true;

      breedController
        .updateBreed(animalBreed.value)
        .then((response) => {
          loading.value = false;
          if (response.getValue()) {
            Swal.fire({
              text: t(SWAL_MESSAGES.SUCCESS_UPDATE_BREED),
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT),
              customClass: {
                confirmButton: "btn btn-primary",
              },
            }).then(() => {
              hideModal(newTargetModalRef.value);
              window.location.reload();
            });
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    const updatePriceSubmit = async () => {
      const priceAddModel: PriceAddModel = {
        animalBreed: store.getters.selectedBreedId,
        unitPrice: breedPrice.value.unitPrice,
      };

      breedController.updatePrice(priceAddModel).then((response) => {
        if (response.isSuccess) {
          swalNotification.success(
            t(SWAL_MESSAGES.SUCCESS_UPDATE_PRICE),
            t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
          );
          window.location.reload();
        } else {
          swalNotification.error(
            response.error.cause.cause +
              t(SWAL_MESSAGES.ERROR_CODE) +
              response.error.code +
              response.error.cause.code,
            t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
          );
        }
      });
    };

    onMounted(async () => {
      setCurrentPageTitle(t(PAGE_TITLE_PATH.BREED));
      getAnimalTypes();
      getBreeds(1);
    });

    return {
      PAGE_TITLE_PATH,
      removeBreed,
      updateBreed,
      selectBreed,
      isLoading,
      loading,
      animalBreed,
      updatePriceSubmit,
      updateBreedSubmit,
      getBreeds,
      originalBreedList,
      breedList,
      animalTypeList,
      totalItems,
      currentPage,
      pageCount,
      pageSize,
      breedPrice,
      currencyValue: "TL",
      form,
      updateBreedForm,
    };
  },
});
</script>

<style lang="scss">
table.dataTable {
  clear: both;
  margin-top: 6px !important;
  margin-bottom: 6px !important;
  max-width: none !important;
  border-collapse: separate !important;
  border-spacing: 0;
}

table.dataTable > thead {
  th.sorting {
    position: relative;
  }
  .sorting:after {
    position: absolute;
  }
}

.el-pagination.is-background .btn-next,
.el-pagination.is-background .btn-prev,
.el-pagination.is-background .el-pager li {
  background: none;
  border-radius: 0.475rem;
  font-weight: 500;
  font-size: 1.075rem;
  font-family: Poppins, Helvetica, sans-serif;
}

.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #009ef7;
}
</style>
